body {
  color: $txt;
  line-height: 2em;
  font-family: 'Muli', sans-serif !important;
  @include fr;
  overflow-x: hidden !important;

  &.information-information {

    &:not(.iid-6):not(.iid-3):not(.iid-5) {
      >.container {
        >.row {
          display: none;
        }
      }

      >#footer-area {
        margin-top: 0;
      }
    }
  }

  &:not(.common-home) {
    &::before {
      content: '';
      position: absolute;
      width: 3px;
      height: 40%;
      left: 0;
      top: calc(50% - 15%);
      background-color: $p;
    }
  }
}

b {
  margin-left: 5px !important;
}

.container {
  width: 80%;

  @include to(lapx) {
    width: 90%;
    min-width: auto;
  }

  @include to(smx) {
    width: 100%;
  }
}

::selection {
  color: $w;
  background-color: $pd;
}

a {
  @include t;
}

img {
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $b;
  @include fb;
}

.sec {
  padding: 70px 0;

  @include to(tab) {
    padding: 100px 0;
  }

  &top {
    padding: 20px 0 70px;

    @include to(tab) {
      padding: 40px 0 100px;
    }
  }

  &_title {

    >* {
      text-align: left !important;
      margin-bottom: 20px !important;
      font-size: 24px;

      @include to(mob) {
        font-size: 30px;
      }

      &::after {
        background: $s !important;
        height: 3px !important;
        margin: 20px 0 0 !important;
      }
    }

    &.center_title {
      >* {
        text-align: center !important;

        &::after {
          margin: 20px auto 0 auto !important;
        }
      }
    }
  }
}

h2 {
  &::after {
    background: $s !important;
    height: 3px !important;
  }
}

.pt {
  &1 {
    padding-top: 10px !important;
  }

  &2 {
    padding-top: 20px !important;
  }

  &3 {
    padding-top: 30px !important;
  }

  &4 {
    padding-top: 40px !important;
  }

  &5 {
    padding-top: 50px !important;
  }

  &6 {
    padding-top: 60px !important;
  }

  &7 {
    padding-top: 70px !important;
  }

  &8 {
    padding-top: 80px !important;
  }

  &9 {
    padding-top: 90px !important;
  }

  &10 {
    padding-top: 70px !important;

    @include to(tab) {
      padding-top: 100px !important;
    }
  }

}

.rotate {

  @include to(sm) {
    position: absolute;
  }

  >* {
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 0;
    padding: 0;

    @include to(sm) {
      white-space: nowrap;
    }

    @include to(smx) {
      text-align: left;
      margin-bottom: 10px;
    }
  }

  &.rl {
    @include to(sm) {
      top: 0;
      right: calc(100% + 30px);
      transform: rotate(270deg);
      transform-origin: right top;
    }
  }

  &.rr {
    @include to(sm) {
      top: 0;
      left: calc(100% + 30px);
      transform: rotate(90deg);
      transform-origin: left top;
    }
  }

}

.img {
  position: relative;
  z-index: 1;

  img {
    width: 100%;
  }

  @include to(lapx) {
    margin-bottom: 20px;
    text-align: center;
  }
}