$w: #fff;
$b: #000;
$p: #bf2031;
$pd: #a41726;
$s: #0054a6;
$sd: darken($s, 15%);
$t: #e8c68d;
$star: #fbba00;

$txt: #000;
$trans: all 0.2s ease-in-out;
$bxshdw: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important;
$ls: 1px;

$rds: 0;
$rdsbtn: 0;
$bdr: #d7d7d7;
$bg: #f5f5f5;

$fr: 400 !important;
$fsb: 600 !important;
$fb: 700 !important;
$feb: 800 !important;

$deslg: 1920px;
$desmd: 1440px;
$des: 1280px;
$lap: 1024px;
$tab: 992px;
$mob: 768px;
$sm: 576px;