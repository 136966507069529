.modal_container {
  background-color: $w;
  padding: 0;
  position: relative;

  .mfp-close {
    color: $w;
    @include t;

    &:hover {
      transform: rotate(-90deg);
    }
  }

  .modal_banner {
    img {
      width: 100%;
    }
  }

  .modal_content {
    padding: 20px;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      @include to(tabx) {
        flex-direction: column;
      }

      >* {
        &:not(:last-child) {
          margin-right: 5px;

          @include to(tabx) {
            margin-right: 0;
          }
        }
      }

      a {
        display: flex;
        align-items: center;

        &:hover {
          color: $pd;
        }
      }
    }

    img {
      width: 24px;
      @include t;

      &:hover {
        transform: rotate(-360deg);
      }
    }
  }
}

.product-product {
  #enquiry_modal {
    padding: 20px;
  }
}

#enquiry_modal {
  .mfp-close {
    color: $b !important;
  }
}