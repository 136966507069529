@mixin to($media) {
  @if $media==deslg {
    @media (min-width: $deslg + 1px) {
      @content;
    }
  }

  @if $media==deslgx {
    @media (max-width: $deslg) {
      @content;
    }
  }


  @else if $media==desmd {
    @media (min-width: $desmd + 1px) {
      @content;
    }
  }

  @else if $media==desmdx {
    @media (max-width: $desmd) {
      @content;
    }
  }

  @else if $media==des {
    @media (min-width: $des + 1px) {
      @content;
    }
  }

  @else if $media==desx {
    @media (max-width: $des) {
      @content;
    }
  }

  @else if $media==lap {
    @media (min-width: $lap + 1px) {
      @content;
    }
  }

  @else if $media==lapx {
    @media (max-width: $lap) {
      @content;
    }
  }

  @else if $media==moblap {
    @media (min-width: $mob) and (max-width: $lap) {
      @content;
    }
  }

  @else if $media==tab {
    @media (min-width: $tab) {
      @content;
    }
  }

  @else if $media==tabx {
    @media (max-width: $tab) {
      @content;
    }
  }

  @else if $media==tabo {
    @media (min-width: 320px) and (max-width: $tab) {
      @content;
    }
  }

  @else if $media==mob {
    @media (min-width: $mob + 1px) {
      @content;
    }
  }

  @else if $media==mobx {
    @media (max-width: $mob) {
      @content;
    }
  }

  @else if $media==mobo {
    @media (min-width: 320px) and (max-width: $mob) {
      @content;
    }
  }

  @else if $media==sm {
    @media (min-width: $sm + 1px) {
      @content;
    }
  }

  @else if $media==smx {
    @media (max-width: $sm) {
      @content;
    }
  }

  @else if $media==smo {
    @media (min-width: 320px) and (max-width: $sm - 1px) {
      @content;
    }
  }

  @else {
    @media (max-width: $media + px) {
      @content;
    }
  }
}

@mixin t {
  transition: $trans;
}

@mixin bs {
  box-shadow: $bxshdw;
}

@mixin spin {
  animation: spin .2s linear;
}

@mixin va {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin ha {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fr {
  font-weight: $fr;
}

@mixin fsb {
  font-weight: $fsb;
}

@mixin fb {
  font-weight: $fb;
}

@mixin feb {
  font-weight: $feb;
}