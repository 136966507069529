.product-view {
  padding: 5px !important;

  .product-block {
    flex: 0 1 auto;
    width: calc((99% / 2) - 20px);
    margin: 10px !important;

    @include to(sm) {
      width: calc((99% / 2) - 20px);
    }

    @include to(tab) {
      width: calc((99% / 3) - 20px);
    }
  }
}

#product-filter-replace {
  >h2 {
    display: none;
  }
}

.product-block {
  border: 0;

  .product-image-block {
    overflow: hidden;

    >a {
      display: block;
    }
  }

  .view_details_btn {
    @include t;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    color: $w;
    text-transform: uppercase;
    background-color: rgba($p, 0.6);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
      border-radius: 100%;
      font-size: 24px;
      display: block;
      color: $b !important;
      width: 50px;
      height: 50px;
      background-color: #fff !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover {
    .view_details_btn {
      height: 100%;
      opacity: 1;
    }
  }

  // More Option
  .more-options-text {
    display: none;
  }

  // Product Name
  .product-name {

    a {
      font-size: 16px;
      @include fsb;
      text-transform: uppercase;
      color: $txt;
    }
  }

  // Product Price
  .product-details {
    width: 100%;

    .price {
      color: $txt;

      >* {
        font-size: 16px !important;
      }

      .price-new {
        color: $txt;
      }

      .price-old {
        color: $txt;
        opacity: .5;
        text-decoration: line-through;
      }
    }

    .enquiry-item {
      color: $txt;
      font-weight: normal;
      text-transform: capitalize;
      background-color: transparent !important;
      font-size: 14px !important;
    }
  }

  .product-list-color-options {
    display: flex;
    margin-top: 10px;
    padding: 0 7px;
    justify-content: flex-start;

    >* {
      flex: 0 1 auto;
      cursor: pointer;
    }

    img {
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }
  }
}

.sort_container {
  display: flex;
  justify-content: flex-end;

  .input-group-addon {
    display: none;
  }

  >* {
    &:first-child {
      display: none;
    }
  }

  .col-xs-6 {
    @include to(smx) {
      width: 100% !important;
    }
  }
}