.fixed-header {
  left: 0 !important;
  background: linear-gradient(246deg, rgba($p, 1) 0%, rgba($p, 1) 38%, rgba($w, 1) 38%, rgba($w, 1) 100%);
  box-shadow: none !important;

  @include to(sm) {
    background: linear-gradient(246deg, rgba($p, 1) 0%, rgba($p, 1) 60%, rgba($w, 1) 60%, rgba($w, 1) 100%);
  }

  @include to(tab) {
    background: linear-gradient(246deg, rgba($p, 1) 0%, rgba($p, 1) 71%, rgba($w, 1) 71%, rgba($w, 1) 100%);
  }


  >.container {
    padding: 0 15px;
    width: 90%;
  }

  .header-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    >* {
      flex: 0 1 auto;
    }

    // #cart {

    //   #cart_dropdown_icon {

    //     >.badge {
    //       display: none;
    //     }
    //   }
    // }
  }

  .header-top {
    order: 3;

    .fa {
      color: $w;
      @include t;

      &:hover {
        opacity: .5;
      }
    }

    .dropdown-menu {
      a {
        text-transform: uppercase;

        &:hover {
          background-color: transparent;
          color: $s;
        }
      }
    }

    .badge {
      background-color: $pd !important;
    }
  }

  .header-logo {
    order: 1;
    margin-right: auto;
  }

  .header-mobile-links {
    order: 2;
  }

  .header-menu {
    order: 2;

    @include to(lap) {
      flex: 1;
    }


    #main-menu {

      .sub-arrow {
        display: none;
      }

      >li {
        position: relative;
        overflow: hidden;

        >ul {
          display: none !important;
        }

        >a {
          letter-spacing: $ls;
          color: $w;
          text-transform: uppercase;
          padding: 50px 10px !important;

          @include to(desx) {
            font-size: 11px;
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }

        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 0;
          @include t;
        }

        &:hover {
          &::before {
            height: 100%;
            background-color: $s;
          }
        }

        &.active {

          &::before {
            background-color: $s;
            height: 100%;
          }

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

#mobileNav {
  color: $w;
}

#sidr {
  background: $p;

  .header-mobile {

    .mobile-account {
      border-color: $pd;
    }

    a {
      letter-spacing: $ls;
      font-size: 15px;
      color: $w;
      border-color: $pd;

      i {
        font-size: 14px;
        color: $w;
      }
    }
  }

  .mobile-search {

    .search-custom {
      padding: 0;
    }

    .search-box {
      background-color: $pd;
      border-color: $pd;

      input {
        background-color: $pd;
        color: rgba($w, .8);

        &::placeholder {
          color: rgba($w, .5);
        }
      }

      .search-btn {
        padding: 0;
      }

      .fa-search {
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        color: $w;
        font-size: 16px;
        @include t;

        &:hover {
          color: $bdr;
        }
      }
    }
  }

  .cd-accordion-menu {

    li {
      border-top: 1px solid $pd;

      &:last-child {
        border-bottom: 1px solid $pd;
      }

      &.has-children {

        label {
          display: none;
        }

        ul {
          display: none !important;
        }
      }

      a {
        color: $w;
        font-size: 15px;
        text-transform: uppercase;
        background: $p;
      }


      &.active {
        >a {
          background-color: $s;
        }
      }

      &.has-children {

        label {
          background-color: #fff;
          color: $txt;
        }

        >ul {
          display: none;
        }
      }
    }
  }
}