.breadcrumb {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 70px 0 20px;

  @include to(tab) {
    margin: 70px 0 0;
  }

  li {
    &::after {
      content: '/';
      color: $b;
    }

    &:first-child {
      padding-left: 0;
    }

    a {
      color: $b;
      text-transform: uppercase;
      font-size: 16px;

      &:hover {
        color: $p;
      }
    }
  }
}

.product-category {
  .breadcrumb {
    margin-bottom: 50px;

    >li {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

.product-product {
  .breadcrumb {
    margin-bottom: 50px;
  }
}

.information-contact {
  .breadcrumb {
    margin-bottom: 50px;
  }
}