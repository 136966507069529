#ToTop {
  display: none !important;
}

#footer-area {
  background-color: $pd;

  footer {
    text-transform: uppercase;
    padding: 50px 0;
    background: linear-gradient(246deg, rgba($pd, 1) 0%, rgba($pd, 1) 65%, rgba($p, 1) 65%, rgba($p, 1) 100%);
  }

  a {
    color: $w;

    &:hover {
      opacity: .5;
    }
  }

  .footer-upper-contet {
    justify-content: space-between;
    flex-wrap: wrap;

    @include to(smx) {
      flex-direction: column;
    }

    >* {
      flex: 0 1 auto;

      @include to(sm) {
        width: calc((100% / 3) - 20px);
      }

      &:not(:last-child) {
        @include to(smx) {
          margin-bottom: 20px;
        }
      }
    }


    .footer-contact-info {
      order: 2;
    }

    .footer-contact-links {
      order: 1;

      @include to(smx) {
        display: none;
      }

      ul {
        margin-bottom: 0;
      }
    }

    .footer-social-info {
      order: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .footer-social-icons {
        padding-top: 0;
      }

      .copyright {
        text-transform: auto;
        text-transform: initial;

        @include to(smx) {
          margin-top: 50px;
        }

        p {
          margin-bottom: 0;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
}