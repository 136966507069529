// All Products Filter
#filter-groups {

  >* {
    box-shadow: none !important;
  }

  a {
    color: rgba($b, 0.6);

    &:hover {
      color: $b !important;
      background-color: transparent !important;
    }
  }

  .active {
    a {
      color: $b;
    }
  }


  .list-group-item {
    padding: 7px 10px;
  }

  .list-group-item,
  .list-group {
    border: 0 !important;

    >* {
      border: 0 !important;
    }
  }

  // Filter Title
  .item-header {
    color: $b;
    padding: 0;
    font-weight: bold;
  }

  #side_filter {

    .item-header {

      i {
        cursor: pointer;
        padding: 0 10px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0);
        @include t;

        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }

  #side-manufacturer,
  #side_filter {
    margin-bottom: 30px;

    .item-header {
      margin: 0;
      border: 0 !important;
      border-bottom: 2px solid $s !important;
      color: $b !important;
      text-transform: uppercase;
      font-size: 12px !important;
      @include fb;
      padding: 7px;

      @include to(lap) {
        font-size: 18px !important;
      }

      @include to(desmd) {
        font-size: 24px !important;
      }

    }

    .checkmark {
      display: none;
    }

    .checkbox_name {
      color: rgba($b, 0.6);
      @include t;

      &:hover {
        color: $b;
      }
    }

    input {
      &:checked~.checkmark~.checkbox_name {
        color: $b;
      }
    }
  }

  // Filter Categories
  #side-categories {
    user-select: none;

    >.item-header {
      display: none;
    }

    .side-categories-level-1 {
      >.group {
        >.level-1 {
          display: none !important;
        }

        >.level-2 {
          border: 0 !important;

          >.group {
            border: 0 !important;
            margin-bottom: 30px;

            >.level-2 {
              border: 0 !important;
              border-bottom: 2px solid $s !important;

              >.sub {
                display: block;
              }

              .fa {
                transform: rotate(0);
                @include t;
              }

              &.active {
                .fa {
                  transform: rotate(180deg);
                }
              }

              >a {
                color: $b !important;
                text-transform: uppercase;
                font-size: 12px !important;
                @include fb;

                @include to(lap) {
                  font-size: 18px !important;
                }

                @include to(desmd) {
                  font-size: 24px !important;
                }
              }

              .pointer {
                border: 0 !important;
                background-color: transparent !important;
                color: $b !important;
              }
            }
          }
        }

        .level-3 {
          border: 0 !important;

          .active {
            color: $p;
          }

          a {
            padding-left: 15px;
          }
        }
      }
    }
  }

  // Filter Price
  #side-price {
    display: none;

    .input-group {
      align-items: center;
      display: flex;

      >* {
        flex: 0 1 auto;
      }

      label {
        margin-bottom: 0;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }

    .price-container {

      label {
        padding-right: 0;
      }

      input {
        border: 0 !important;
        box-shadow: none !important;
        background-color: transparent;

        width: 70%;

        @include to(desx) {
          width: 80%;
        }

        @include to(lapx) {
          width: 100%;
        }
      }
    }

    #slider-price {
      background-color: transparent;
      border: 1px solid #e1e1e1;
      border-radius: $rds;
      height: 5px;

      .ui-slider-range {
        background-color: $p;
      }

      .ui-slider-handle {
        background-color: #fff;
        border: 1px solid $p;
      }
    }
  }
}


body {

  &[class*='cid-5'] {
    .side-categories-level-1 {

      &:first-child {
        display: none;
      }
    }
  }

  &[class*='cid-6'] {
    .side-categories-level-1 {

      &:last-child {
        display: none;
      }
    }
  }
}