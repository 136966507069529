#home_page {
  #sec_1 {
    padding-bottom: 0;

    .img_video_container {
      z-index: 1;

      @include to(lap) {
        width: 50%;
        order: 1;
      }

      p {
        margin-bottom: 0 !important;
      }
    }

    .content {
      z-index: 0;
      padding: 20px;
      position: relative;

      @include to(lap) {
        order: 2;
        width: 50%;
        padding: 20px 50px;
      }

      &::before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 70px;
        background-color: #ebebeb;
      }

      .sec_title {
        @include to(des) {
          width: 80%;
        }

        @include to(desmd) {
          width: 60%;
        }
      }

      >p {

        @include to(lap) {
          margin-top: -45px;
          padding-left: 88px;
        }

        @include to(des) {
          width: 80%;

        }
      }
    }
  }

  #sec_2 {
    padding-top: 0 !important;

    .img {
      align-self: flex-start;

      @include to(lap) {
        width: 55%;
        order: 2;
      }
    }

    .content {
      @include to(lap) {
        width: 45%;
        padding-top: 100px;
        padding-right: 40px;
      }
    }

    .car_types {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 100%;
        padding: 30px 20px;
        flex: 0 1 auto;
        margin: 10px;
        text-align: center;
        @include bs;

        @include to(sm) {
          width: calc(50% - 20px);
        }

        @include to(des) {
          width: calc((100% / 3) - 20px);
        }
      }

      .title {
        margin-top: 10px;
        font-size: 18px;
        @include fsb;
        text-transform: uppercase;
        margin-bottom: 0;

        a {
          color: $b;

          &:hover {
            color: $p;
          }
        }
      }
    }
  }

  #sec_3 {
    padding-top: 0;
    background: linear-gradient(0deg, #f5f5f5 0%, #f5f5f5 50%, $w 50%, $w 100%);

    .img {

      @include to(lap) {
        width: 45%;
      }
    }

    .content {
      @include to(lap) {
        width: 55%;
        padding-left: 40px;
      }
    }

    .ls_periods {
      display: flex;
      flex-wrap: wrap;

      @include to(desmd) {
        width: 80%;
      }

      .item {
        background-color: $w;
        width: 100%;
        padding: 30px 20px;
        flex: 0 1 auto;
        margin: 10px;
        text-align: center;
        @include bs;

        @include to(sm) {
          width: calc(50% - 20px);
        }
      }

      .title {
        margin-top: 10px;
        font-size: 18px;
        @include fsb;
        text-transform: uppercase;
        margin-bottom: 0;

        a {
          color: $b;

          &:hover {
            color: $p;
          }
        }
      }
    }
  }
}

.common-home {
  .featured-module {
    padding-top: 0;
    background-color: #f5f5f5;

    >.featured {
      padding: 0;

      >* {
        &:first-child {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .slick-list {
            width: 100%;
            order: 1;
            margin-bottom: 50px;
          }

          .slick-nav {
            order: 2;
            left: auto;
            right: auto;
            width: auto;

            &.prev {
              margin-right: 5%;

              @include to(sm) {
                margin-right: 25%;
              }
            }

            &.next {
              margin-left: 5%;

              @include to(sm) {
                margin-left: 25%;
              }
            }
          }

          .icon_con {
            color: $w;
            background-color: #a9a9a9;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;

            i {
              font-size: 18px;
            }
          }
        }
      }
    }

    .btn_con {
      text-align: center;
      margin-top: 20px;

      @include to(sm) {
        margin-top: -42px;
      }
    }

    .product-block {
      background-color: #f5f5f5;
      padding-bottom: 0;

      .product-details {
        display: none;
      }
    }
  }
}