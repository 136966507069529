.side_nav {
  position: fixed;
  right: 5px;
  top: 30%;
  z-index: 5;

  @include to(sm) {
    top: 20%;
  }

  .side_nav_content {
    display: flex;
    flex-direction: column;

    >* {
      &:first-child {
        margin-bottom: 10px;
      }

      img {
        @include to(smx) {
          width: 50px;
        }
      }
    }
  }
}