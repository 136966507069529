a {
  color: $p;
}

button {
  @include t;
}

.btn {
  border-radius: $rds;
  @include t;

  &.btn-primary,
  &.btn-default {
    color: $b !important;
    background: transparent !important;
    border: 2px solid $b !important;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
      content: '';
      height: 0;
      width: 150%;
      background: $p !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: -1;
      @include t;
    }

    &:hover {
      color: $w !important;
      border-color: $p !important;

      &::before {
        height: 450%;
        background: $p;
      }
    }
  }

  // &.btn-default {
  //   &:not(#filter_group_tigger_open) {
  //     @include to(smx) {
  //       min-width: 100% !important;
  //     }
  //   }
  // }

  &:focus {
    outline: none !important;
  }

  &.btn-number {
    border-width: 1px !important;
    font-size: 12px !important;
  }
}


#button-login {
  border-width: 1px !important;
}

#button-confirm {

  &:hover {
    background-color: $p !important;
  }
}

#filter_group_tigger_open {
  background-color: $p !important;
  border-color: $p !important;
}

.cart-dorpdown-footer {

  a {

    &:hover {
      color: #fff !important;
    }
  }
}

.label-primary {
  background-color: $p !important;
}

.swal2-confirm {
  color: #fff;
  background-color: $p !important;
  border: $p !important;
}

#quickcheckout-back {
  background-color: $s;
  border-color: $s;

  &:hover {
    background-color: $p;
    border-color: $p;
  }
}

span.label.out-of-stock {
  font-size: 14px;
  font-weight: normal !important;
  display: inline-block;
  min-width: 170px;
  padding: 16px 14px;
  text-transform: uppercase;
  border-radius: $rds;

  @include to(smx) {
    min-width: 100%;
  }
}