.fx {
  &l {
    @include to(lap) {
      display: flex;
    }
  }

  &i {
    @include to(lap) {
      flex: 0 1 auto;
    }
  }
}

.aic {
  @include to(lap) {
    align-items: center;
  }
}