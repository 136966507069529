.pagination {
  display: flex;
  justify-content: center;

  >li {

    i {
      font-size: 18px;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }

    >* {
      border-width: 2px !important;
      margin: 0 !important;
      color: $txt !important;
      border-radius: 100% !important;
      border-color: $bdr !important;
    }

    &.active,
    &:focus,
    &:hover {
      >* {
        color: $p !important;
        background-color: transparent !important;
        border-color: $p !important;
      }
    }

    &.pagination_arrow {
      display: flex;
      align-items: center;

      >* {
        color: $p !important;
        padding: 0 12px;

        i {
          font-size: 30px;
        }

        border: 0 !important;
      }
    }
  }
}