/*!
 Author:         Bankai
 Template:       Opencart
*/
@import url("https://fonts.googleapis.com/css?family=Muli:400,600,700,800&display=swap");
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes floating {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 15px); }
  to {
    transform: translate(0, 0px); } }

body {
  color: #000;
  line-height: 2em;
  font-family: 'Muli', sans-serif !important;
  font-weight: 400 !important;
  overflow-x: hidden !important; }
  body.information-information:not(.iid-6):not(.iid-3):not(.iid-5) > .container > .row {
    display: none; }
  body.information-information:not(.iid-6):not(.iid-3):not(.iid-5) > #footer-area {
    margin-top: 0; }
  body:not(.common-home)::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 40%;
    left: 0;
    top: calc(50% - 15%);
    background-color: #bf2031; }

b {
  margin-left: 5px !important; }

.container {
  width: 80%; }
  @media (max-width: 1024px) {
    .container {
      width: 90%;
      min-width: auto; } }
  @media (max-width: 576px) {
    .container {
      width: 100%; } }

::selection {
  color: #fff;
  background-color: #a41726; }

a {
  transition: all 0.2s ease-in-out; }

img {
  user-select: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 700 !important; }

.sec {
  padding: 70px 0; }
  @media (min-width: 992px) {
    .sec {
      padding: 100px 0; } }
  .sectop {
    padding: 20px 0 70px; }
    @media (min-width: 992px) {
      .sectop {
        padding: 40px 0 100px; } }
  .sec_title > * {
    text-align: left !important;
    margin-bottom: 20px !important;
    font-size: 24px; }
    @media (min-width: 769px) {
      .sec_title > * {
        font-size: 30px; } }
    .sec_title > *::after {
      background: #0054a6 !important;
      height: 3px !important;
      margin: 20px 0 0 !important; }
  .sec_title.center_title > * {
    text-align: center !important; }
    .sec_title.center_title > *::after {
      margin: 20px auto 0 auto !important; }

h2::after {
  background: #0054a6 !important;
  height: 3px !important; }

.pt1 {
  padding-top: 10px !important; }

.pt2 {
  padding-top: 20px !important; }

.pt3 {
  padding-top: 30px !important; }

.pt4 {
  padding-top: 40px !important; }

.pt5 {
  padding-top: 50px !important; }

.pt6 {
  padding-top: 60px !important; }

.pt7 {
  padding-top: 70px !important; }

.pt8 {
  padding-top: 80px !important; }

.pt9 {
  padding-top: 90px !important; }

.pt10 {
  padding-top: 70px !important; }
  @media (min-width: 992px) {
    .pt10 {
      padding-top: 100px !important; } }

@media (min-width: 577px) {
  .rotate {
    position: absolute; } }

.rotate > * {
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;
  padding: 0; }
  @media (min-width: 577px) {
    .rotate > * {
      white-space: nowrap; } }
  @media (max-width: 576px) {
    .rotate > * {
      text-align: left;
      margin-bottom: 10px; } }

@media (min-width: 577px) {
  .rotate.rl {
    top: 0;
    right: calc(100% + 30px);
    transform: rotate(270deg);
    transform-origin: right top; } }

@media (min-width: 577px) {
  .rotate.rr {
    top: 0;
    left: calc(100% + 30px);
    transform: rotate(90deg);
    transform-origin: left top; } }

.img {
  position: relative;
  z-index: 1; }
  .img img {
    width: 100%; }
  @media (max-width: 1024px) {
    .img {
      margin-bottom: 20px;
      text-align: center; } }

@media (min-width: 1025px) {
  .fxl {
    display: flex; } }

@media (min-width: 1025px) {
  .fxi {
    flex: 0 1 auto; } }

@media (min-width: 1025px) {
  .aic {
    align-items: center; } }

.fixed-header {
  left: 0 !important;
  background: linear-gradient(246deg, #bf2031 0%, #bf2031 38%, white 38%, white 100%);
  box-shadow: none !important; }
  @media (min-width: 577px) {
    .fixed-header {
      background: linear-gradient(246deg, #bf2031 0%, #bf2031 60%, white 60%, white 100%); } }
  @media (min-width: 992px) {
    .fixed-header {
      background: linear-gradient(246deg, #bf2031 0%, #bf2031 71%, white 71%, white 100%); } }
  .fixed-header > .container {
    padding: 0 15px;
    width: 90%; }
  .fixed-header .header-container {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .fixed-header .header-container > * {
      flex: 0 1 auto; }
  .fixed-header .header-top {
    order: 3; }
    .fixed-header .header-top .fa {
      color: #fff;
      transition: all 0.2s ease-in-out; }
      .fixed-header .header-top .fa:hover {
        opacity: .5; }
    .fixed-header .header-top .dropdown-menu a {
      text-transform: uppercase; }
      .fixed-header .header-top .dropdown-menu a:hover {
        background-color: transparent;
        color: #0054a6; }
    .fixed-header .header-top .badge {
      background-color: #a41726 !important; }
  .fixed-header .header-logo {
    order: 1;
    margin-right: auto; }
  .fixed-header .header-mobile-links {
    order: 2; }
  .fixed-header .header-menu {
    order: 2; }
    @media (min-width: 1025px) {
      .fixed-header .header-menu {
        flex: 1; } }
    .fixed-header .header-menu #main-menu .sub-arrow {
      display: none; }
    .fixed-header .header-menu #main-menu > li {
      position: relative;
      overflow: hidden; }
      .fixed-header .header-menu #main-menu > li > ul {
        display: none !important; }
      .fixed-header .header-menu #main-menu > li > a {
        letter-spacing: 1px;
        color: #fff;
        text-transform: uppercase;
        padding: 50px 10px !important; }
        @media (max-width: 1280px) {
          .fixed-header .header-menu #main-menu > li > a {
            font-size: 11px;
            padding-left: 5px !important;
            padding-right: 5px !important; } }
      .fixed-header .header-menu #main-menu > li::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 0;
        transition: all 0.2s ease-in-out; }
      .fixed-header .header-menu #main-menu > li:hover::before {
        height: 100%;
        background-color: #0054a6; }
      .fixed-header .header-menu #main-menu > li.active::before {
        background-color: #0054a6;
        height: 100%; }
      .fixed-header .header-menu #main-menu > li.active a {
        text-decoration: underline; }

#mobileNav {
  color: #fff; }

#sidr {
  background: #bf2031; }
  #sidr .header-mobile .mobile-account {
    border-color: #a41726; }
  #sidr .header-mobile a {
    letter-spacing: 1px;
    font-size: 15px;
    color: #fff;
    border-color: #a41726; }
    #sidr .header-mobile a i {
      font-size: 14px;
      color: #fff; }
  #sidr .mobile-search .search-custom {
    padding: 0; }
  #sidr .mobile-search .search-box {
    background-color: #a41726;
    border-color: #a41726; }
    #sidr .mobile-search .search-box input {
      background-color: #a41726;
      color: rgba(255, 255, 255, 0.8); }
      #sidr .mobile-search .search-box input::placeholder {
        color: rgba(255, 255, 255, 0.5); }
    #sidr .mobile-search .search-box .search-btn {
      padding: 0; }
    #sidr .mobile-search .search-box .fa-search {
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      transition: all 0.2s ease-in-out; }
      #sidr .mobile-search .search-box .fa-search:hover {
        color: #d7d7d7; }
  #sidr .cd-accordion-menu li {
    border-top: 1px solid #a41726; }
    #sidr .cd-accordion-menu li:last-child {
      border-bottom: 1px solid #a41726; }
    #sidr .cd-accordion-menu li.has-children label {
      display: none; }
    #sidr .cd-accordion-menu li.has-children ul {
      display: none !important; }
    #sidr .cd-accordion-menu li a {
      color: #fff;
      font-size: 15px;
      text-transform: uppercase;
      background: #bf2031; }
    #sidr .cd-accordion-menu li.active > a {
      background-color: #0054a6; }
    #sidr .cd-accordion-menu li.has-children label {
      background-color: #fff;
      color: #000; }
    #sidr .cd-accordion-menu li.has-children > ul {
      display: none; }

#ToTop {
  display: none !important; }

#footer-area {
  background-color: #a41726; }
  #footer-area footer {
    text-transform: uppercase;
    padding: 50px 0;
    background: linear-gradient(246deg, #a41726 0%, #a41726 65%, #bf2031 65%, #bf2031 100%); }
  #footer-area a {
    color: #fff; }
    #footer-area a:hover {
      opacity: .5; }
  #footer-area .footer-upper-contet {
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 576px) {
      #footer-area .footer-upper-contet {
        flex-direction: column; } }
    #footer-area .footer-upper-contet > * {
      flex: 0 1 auto; }
      @media (min-width: 577px) {
        #footer-area .footer-upper-contet > * {
          width: calc((100% / 3) - 20px); } }
      @media (max-width: 576px) {
        #footer-area .footer-upper-contet > *:not(:last-child) {
          margin-bottom: 20px; } }
    #footer-area .footer-upper-contet .footer-contact-info {
      order: 2; }
    #footer-area .footer-upper-contet .footer-contact-links {
      order: 1; }
      @media (max-width: 576px) {
        #footer-area .footer-upper-contet .footer-contact-links {
          display: none; } }
      #footer-area .footer-upper-contet .footer-contact-links ul {
        margin-bottom: 0; }
    #footer-area .footer-upper-contet .footer-social-info {
      order: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      #footer-area .footer-upper-contet .footer-social-info .footer-social-icons {
        padding-top: 0; }
      #footer-area .footer-upper-contet .footer-social-info .copyright {
        text-transform: auto;
        text-transform: initial; }
        @media (max-width: 576px) {
          #footer-area .footer-upper-contet .footer-social-info .copyright {
            margin-top: 50px; } }
        #footer-area .footer-upper-contet .footer-social-info .copyright p {
          margin-bottom: 0; }
        #footer-area .footer-upper-contet .footer-social-info .copyright a {
          text-decoration: none; }

.breadcrumb {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 70px 0 20px; }
  @media (min-width: 992px) {
    .breadcrumb {
      margin: 70px 0 0; } }
  .breadcrumb li::after {
    content: '/';
    color: #000; }
  .breadcrumb li:first-child {
    padding-left: 0; }
  .breadcrumb li a {
    color: #000;
    text-transform: uppercase;
    font-size: 16px; }
    .breadcrumb li a:hover {
      color: #bf2031; }

.product-category .breadcrumb {
  margin-bottom: 50px; }
  .product-category .breadcrumb > li:nth-child(2) {
    display: none; }

.product-product .breadcrumb {
  margin-bottom: 50px; }

.information-contact .breadcrumb {
  margin-bottom: 50px; }

.slider-slideshow-description-texts > [class^=slideshow-text-] {
  color: #fff !important;
  font-size: 20px !important;
  text-transform: uppercase;
  text-shadow: 0px 0px 10px #000;
  font-weight: 700 !important; }
  @media (min-width: 1025px) {
    .slider-slideshow-description-texts > [class^=slideshow-text-] {
      font-size: 34px !important; } }

.slider-slideshow-description-texts .slider-slideshow-description-link .btn {
  color: #fff !important;
  border-color: #fff !important; }
  .slider-slideshow-description-texts .slider-slideshow-description-link .btn:hover {
    border-color: #bf2031 !important; }

.owl-nav {
  position: absolute;
  bottom: 10%;
  right: 10%;
  display: flex;
  z-index: 2; }
  @media (max-width: 1024px) {
    .owl-nav {
      display: none; } }
  .owl-nav > * {
    width: auto !important;
    position: initial; }
  .owl-nav .arrows {
    width: 50px;
    height: 50px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 3px; }
    .owl-nav .arrows i {
      font-size: 24px; }
  .owl-nav .owl-prev {
    order: 1; }
  .owl-nav .owl-next {
    order: 2; }

.slider-dots {
  padding: 0px 47px;
  bottom: 10%;
  right: 0; }
  .slider-dots .owl-dot {
    width: 35px;
    border-radius: 0 !important;
    height: 5px !important;
    background-color: #fff !important;
    transition: all 0.2s ease-in-out; }
    @media (min-width: 577px) {
      .slider-dots .owl-dot {
        width: 60px !important; } }
    .slider-dots .owl-dot.active {
      background-color: #0054a6 !important; }

a {
  color: #bf2031; }

button {
  transition: all 0.2s ease-in-out; }

.btn {
  border-radius: 0;
  transition: all 0.2s ease-in-out; }
  .btn.btn-primary, .btn.btn-default {
    color: #000 !important;
    background: transparent !important;
    border: 2px solid #000 !important;
    position: relative;
    overflow: hidden;
    z-index: 1; }
    .btn.btn-primary::before, .btn.btn-default::before {
      content: '';
      height: 0;
      width: 150%;
      background: #bf2031 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: -1;
      transition: all 0.2s ease-in-out; }
    .btn.btn-primary:hover, .btn.btn-default:hover {
      color: #fff !important;
      border-color: #bf2031 !important; }
      .btn.btn-primary:hover::before, .btn.btn-default:hover::before {
        height: 450%;
        background: #bf2031; }
  .btn:focus {
    outline: none !important; }
  .btn.btn-number {
    border-width: 1px !important;
    font-size: 12px !important; }

#button-login {
  border-width: 1px !important; }

#button-confirm:hover {
  background-color: #bf2031 !important; }

#filter_group_tigger_open {
  background-color: #bf2031 !important;
  border-color: #bf2031 !important; }

.cart-dorpdown-footer a:hover {
  color: #fff !important; }

.label-primary {
  background-color: #bf2031 !important; }

.swal2-confirm {
  color: #fff;
  background-color: #bf2031 !important;
  border: #bf2031 !important; }

#quickcheckout-back {
  background-color: #0054a6;
  border-color: #0054a6; }
  #quickcheckout-back:hover {
    background-color: #bf2031;
    border-color: #bf2031; }

span.label.out-of-stock {
  font-size: 14px;
  font-weight: normal !important;
  display: inline-block;
  min-width: 170px;
  padding: 16px 14px;
  text-transform: uppercase;
  border-radius: 0; }
  @media (max-width: 576px) {
    span.label.out-of-stock {
      min-width: 100%; } }

input,
textarea {
  box-shadow: none !important;
  outline: none;
  transition: all 0.2s ease-in-out; }
  input:not([name=search]),
  textarea:not([name=search]) {
    border: 1px solid #d7d7d7 !important; }
  input:focus,
  textarea:focus {
    outline: none;
    box-shadow: none !important;
    border-color: #000 !important; }
  input::-ms-clear,
  textarea::-ms-clear {
    display: none !important; }

select {
  padding: 10px !important;
  height: auto !important;
  border: 1px solid #d7d7d7 !important;
  box-shadow: none !important;
  appearance: none !important;
  background-image: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%) !important;
  background-position: calc(100% - 15px) calc(1em + 6px), calc(100% - 10px) calc(1em + 6px) !important;
  background-size: 5px 5px, 5px 5px !important;
  background-repeat: no-repeat !important; }
  select::-ms-expand {
    display: none; }
  select:focus {
    outline: none;
    box-shadow: none !important;
    border-color: #000 !important; }

#google_recaptcha {
  transform: inherit;
  transform-origin: none;
  margin-bottom: 15px !important;
  margin-left: 0 !important; }
  @media (min-width: 769px) {
    #google_recaptcha {
      margin-bottom: 0 !important; } }

.g-recaptcha {
  transform: scale(0.7);
  transform-origin: 0; }
  @media (max-width: 768px) {
    .g-recaptcha {
      transform-origin: center; } }

.form-item .title {
  font-size: 16px;
  margin-bottom: 5px; }

.form-item .text-danger {
  padding-left: 1px;
  margin-top: 5px;
  font-size: 11px; }

.form-item .rating {
  display: flex;
  align-items: center; }
  .form-item .rating .radiobox {
    margin-top: 0; }
  .form-item .rating .radiomark {
    margin: 5px !important; }
  .form-item .rating span:first-child {
    margin-right: 5px; }
  .form-item .rating span:last-child {
    margin-left: 5px; }

@media (min-width: 769px) {
  .form-item.items:not(.form-footer) {
    display: flex;
    justify-content: space-between; } }

.form-item.items:not(.form-footer) > .item {
  margin-bottom: 20px; }
  @media (min-width: 769px) {
    .form-item.items:not(.form-footer) > .item {
      flex: 0 1 auto;
      width: calc(50% - 10px); } }

.form-item:not(.items) > .item {
  margin-bottom: 10px; }

.form-item.form-footer {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .form-item.form-footer {
      flex-direction: column; } }

.form-item select {
  width: 100%;
  line-height: 1em;
  background-position: calc(100% - 15px) 1em, calc(100% - 10px) 1em !important;
  text-transform: uppercase; }

.alert {
  border: 0 !important;
  border-radius: 0; }

.rating .fa-star,
#form-review .fa-star {
  color: #fbba00 !important; }
  .rating .fa-star + .fa-star-o,
  #form-review .fa-star + .fa-star-o {
    color: #fbba00 !important; }

::placeholder {
  text-transform: uppercase; }

hr {
  border: 0;
  border-bottom: 2px solid #bf2031; }
  hr.short {
    width: 100px;
    margin-top: 0; }

.section-title {
  margin-bottom: 50px !important; }

.pagination {
  display: flex;
  justify-content: center; }
  .pagination > li i {
    font-size: 18px; }
  .pagination > li:not(:last-child) {
    margin-right: 5px; }
  .pagination > li > * {
    border-width: 2px !important;
    margin: 0 !important;
    color: #000 !important;
    border-radius: 100% !important;
    border-color: #d7d7d7 !important; }
  .pagination > li.active > *, .pagination > li:focus > *, .pagination > li:hover > * {
    color: #bf2031 !important;
    background-color: transparent !important;
    border-color: #bf2031 !important; }
  .pagination > li.pagination_arrow {
    display: flex;
    align-items: center; }
    .pagination > li.pagination_arrow > * {
      color: #bf2031 !important;
      padding: 0 12px;
      border: 0 !important; }
      .pagination > li.pagination_arrow > * i {
        font-size: 30px; }

#filter-groups > * {
  box-shadow: none !important; }

#filter-groups a {
  color: rgba(0, 0, 0, 0.6); }
  #filter-groups a:hover {
    color: #000 !important;
    background-color: transparent !important; }

#filter-groups .active a {
  color: #000; }

#filter-groups .list-group-item {
  padding: 7px 10px; }

#filter-groups .list-group-item,
#filter-groups .list-group {
  border: 0 !important; }
  #filter-groups .list-group-item > *,
  #filter-groups .list-group > * {
    border: 0 !important; }

#filter-groups .item-header {
  color: #000;
  padding: 0;
  font-weight: bold; }

#filter-groups #side_filter .item-header i {
  cursor: pointer;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0);
  transition: all 0.2s ease-in-out; }
  #filter-groups #side_filter .item-header i.active {
    transform: rotate(180deg); }

#filter-groups #side-manufacturer,
#filter-groups #side_filter {
  margin-bottom: 30px; }
  #filter-groups #side-manufacturer .item-header,
  #filter-groups #side_filter .item-header {
    margin: 0;
    border: 0 !important;
    border-bottom: 2px solid #0054a6 !important;
    color: #000 !important;
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 7px; }
    @media (min-width: 1025px) {
      #filter-groups #side-manufacturer .item-header,
      #filter-groups #side_filter .item-header {
        font-size: 18px !important; } }
    @media (min-width: 1441px) {
      #filter-groups #side-manufacturer .item-header,
      #filter-groups #side_filter .item-header {
        font-size: 24px !important; } }
  #filter-groups #side-manufacturer .checkmark,
  #filter-groups #side_filter .checkmark {
    display: none; }
  #filter-groups #side-manufacturer .checkbox_name,
  #filter-groups #side_filter .checkbox_name {
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-in-out; }
    #filter-groups #side-manufacturer .checkbox_name:hover,
    #filter-groups #side_filter .checkbox_name:hover {
      color: #000; }
  #filter-groups #side-manufacturer input:checked ~ .checkmark ~ .checkbox_name,
  #filter-groups #side_filter input:checked ~ .checkmark ~ .checkbox_name {
    color: #000; }

#filter-groups #side-categories {
  user-select: none; }
  #filter-groups #side-categories > .item-header {
    display: none; }
  #filter-groups #side-categories .side-categories-level-1 > .group > .level-1 {
    display: none !important; }
  #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 {
    border: 0 !important; }
    #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group {
      border: 0 !important;
      margin-bottom: 30px; }
      #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2 {
        border: 0 !important;
        border-bottom: 2px solid #0054a6 !important; }
        #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2 > .sub {
          display: block; }
        #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2 .fa {
          transform: rotate(0);
          transition: all 0.2s ease-in-out; }
        #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2.active .fa {
          transform: rotate(180deg); }
        #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2 > a {
          color: #000 !important;
          text-transform: uppercase;
          font-size: 12px !important;
          font-weight: 700 !important; }
          @media (min-width: 1025px) {
            #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2 > a {
              font-size: 18px !important; } }
          @media (min-width: 1441px) {
            #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2 > a {
              font-size: 24px !important; } }
        #filter-groups #side-categories .side-categories-level-1 > .group > .level-2 > .group > .level-2 .pointer {
          border: 0 !important;
          background-color: transparent !important;
          color: #000 !important; }
  #filter-groups #side-categories .side-categories-level-1 > .group .level-3 {
    border: 0 !important; }
    #filter-groups #side-categories .side-categories-level-1 > .group .level-3 .active {
      color: #bf2031; }
    #filter-groups #side-categories .side-categories-level-1 > .group .level-3 a {
      padding-left: 15px; }

#filter-groups #side-price {
  display: none; }
  #filter-groups #side-price .input-group {
    align-items: center;
    display: flex; }
    #filter-groups #side-price .input-group > * {
      flex: 0 1 auto; }
    #filter-groups #side-price .input-group label {
      margin-bottom: 0; }
    #filter-groups #side-price .input-group:last-child {
      justify-content: flex-end; }
  #filter-groups #side-price .price-container label {
    padding-right: 0; }
  #filter-groups #side-price .price-container input {
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent;
    width: 70%; }
    @media (max-width: 1280px) {
      #filter-groups #side-price .price-container input {
        width: 80%; } }
    @media (max-width: 1024px) {
      #filter-groups #side-price .price-container input {
        width: 100%; } }
  #filter-groups #side-price #slider-price {
    background-color: transparent;
    border: 1px solid #e1e1e1;
    border-radius: 0;
    height: 5px; }
    #filter-groups #side-price #slider-price .ui-slider-range {
      background-color: #bf2031; }
    #filter-groups #side-price #slider-price .ui-slider-handle {
      background-color: #fff;
      border: 1px solid #bf2031; }

body[class*='cid-5'] .side-categories-level-1:first-child {
  display: none; }

body[class*='cid-6'] .side-categories-level-1:last-child {
  display: none; }

.product-view {
  padding: 5px !important; }
  .product-view .product-block {
    flex: 0 1 auto;
    width: calc((99% / 2) - 20px);
    margin: 10px !important; }
    @media (min-width: 577px) {
      .product-view .product-block {
        width: calc((99% / 2) - 20px); } }
    @media (min-width: 992px) {
      .product-view .product-block {
        width: calc((99% / 3) - 20px); } }

#product-filter-replace > h2 {
  display: none; }

.product-block {
  border: 0; }
  .product-block .product-image-block {
    overflow: hidden; }
    .product-block .product-image-block > a {
      display: block; }
  .product-block .view_details_btn {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    color: #fff;
    text-transform: uppercase;
    background-color: rgba(191, 32, 49, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .product-block .view_details_btn i {
      border-radius: 100%;
      font-size: 24px;
      display: block;
      color: #000 !important;
      width: 50px;
      height: 50px;
      background-color: #fff !important;
      display: flex;
      align-items: center;
      justify-content: center; }
  .product-block:hover .view_details_btn {
    height: 100%;
    opacity: 1; }
  .product-block .more-options-text {
    display: none; }
  .product-block .product-name a {
    font-size: 16px;
    font-weight: 600 !important;
    text-transform: uppercase;
    color: #000; }
  .product-block .product-details {
    width: 100%; }
    .product-block .product-details .price {
      color: #000; }
      .product-block .product-details .price > * {
        font-size: 16px !important; }
      .product-block .product-details .price .price-new {
        color: #000; }
      .product-block .product-details .price .price-old {
        color: #000;
        opacity: .5;
        text-decoration: line-through; }
    .product-block .product-details .enquiry-item {
      color: #000;
      font-weight: normal;
      text-transform: capitalize;
      background-color: transparent !important;
      font-size: 14px !important; }
  .product-block .product-list-color-options {
    display: flex;
    margin-top: 10px;
    padding: 0 7px;
    justify-content: flex-start; }
    .product-block .product-list-color-options > * {
      flex: 0 1 auto;
      cursor: pointer; }
    .product-block .product-list-color-options img {
      width: 20px;
      height: 20px;
      border-radius: 100%; }

.sort_container {
  display: flex;
  justify-content: flex-end; }
  .sort_container .input-group-addon {
    display: none; }
  .sort_container > *:first-child {
    display: none; }
  @media (max-width: 576px) {
    .sort_container .col-xs-6 {
      width: 100% !important; } }

.product-product .product-name {
  color: #000 !important; }

.product-product .leasing_period {
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 18px;
  margin-bottom: 10px; }

.product-product .product-description {
  margin-bottom: 20px; }

.product-product ul.price-details {
  display: flex;
  margin: 20px 0; }
  .product-product ul.price-details > * {
    color: #bf2031;
    font-weight: 700 !important;
    flex: 0 1 auto;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap; }
  .product-product ul.price-details:not(.price-special):first-child {
    order: 2; }
  .product-product ul.price-details:not(.price-special):last-child {
    order: 1;
    margin-right: 10px; }
  .product-product ul.price-details.price-special:first-child {
    margin-right: 10px; }

.product-product .product-image-column .product-image-main-container {
  overflow: visible; }

.product-product .product-image-column .product-image-additional-container {
  padding: 0; }
  .product-product .product-image-column .product-image-additional-container img {
    padding: 0;
    border: 0; }

.product-product .product-image-column .product-image-additional .slick-current img {
  opacity: 0.7; }

.product-product .product-image-column .slick-track {
  margin-left: 0;
  margin-right: 0; }

.product-product #product .product-btns-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap; }
  .product-product #product .product-btns-container > * {
    margin-bottom: 5px; }
    .product-product #product .product-btns-container > *:not(:last-child) {
      margin-right: 5px; }

.product-product #product .separator_to {
  margin: 0 20px 24px;
  text-align: center; }
  @media (max-width: 1024px) {
    .product-product #product .separator_to {
      width: 80%;
      margin-left: 0;
      margin-right: 0; } }

.product-product .share-container {
  display: none;
  margin: 20px 0; }
  .product-product .share-container a {
    background-color: transparent !important; }
    .product-product .share-container a svg {
      fill: #000 !important; }

.product-product .enquiry-block {
  margin: 20px 0; }
  .product-product .enquiry-block .label {
    text-transform: uppercase;
    color: #000;
    background-color: transparent !important;
    font-size: 18px;
    padding: 0; }

.product-product .product_oos {
  font-weight: 700 !important;
  font-size: 20px;
  color: #a8a8a8 !important;
  margin-bottom: 20px; }

.product-product #waiting_list {
  display: none; }
  @media (max-width: 992px) {
    .product-product #waiting_list .flex-group {
      justify-content: flex-start;
      flex-direction: column; } }
  .product-product #waiting_list .flex-group > *:first-child {
    flex: 1 0 auto; }
    @media (max-width: 992px) {
      .product-product #waiting_list .flex-group > *:first-child {
        margin-bottom: 10px;
        margin-right: 0; } }
  .product-product #waiting_list .flex-group > *:last-child {
    flex: 0 1 auto; }
    @media (max-width: 992px) {
      .product-product #waiting_list .flex-group > *:last-child {
        align-self: flex-end; } }
  .product-product #waiting_list input {
    padding: 13px 10px; }

.product-product #product-tabs {
  margin-top: 30px;
  border-color: #000; }
  .product-product #product-tabs li:not(:last-child) {
    margin-right: 10px; }
  .product-product #product-tabs li a {
    color: #000;
    border: 1px solid #000;
    border-radius: 0;
    margin: 0;
    text-transform: uppercase; }
    @media (min-width: 769px) {
      .product-product #product-tabs li a {
        text-align: center; } }
  .product-product #product-tabs li.active a {
    background-color: #bf2031 !important;
    color: #fff;
    border-color: #bf2031 !important; }

.product-product .tab-content table td {
  padding: 5px; }

.product-product .featured-module {
  margin-top: 70px; }
  .product-product .featured-module .product-details {
    display: none; }
  .product-product .featured-module .slick-list {
    width: 90%;
    margin: 0 auto; }
  .product-product .featured-module .fa {
    background-color: #b0b0b0;
    color: #fff;
    font-size: 18px; }
    .product-product .featured-module .fa::before {
      display: block;
      padding: 15px 30px; }
      @media (max-width: 576px) {
        .product-product .featured-module .fa::before {
          font-size: 16px;
          padding: 5px 10px; } }

.product-product #product {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 1024px) {
    .product-product #product {
      flex-direction: column; } }
  .product-product #product h3 {
    text-transform: uppercase;
    width: 100%; }
  .product-product #product > .form-group {
    width: calc(50% - 53px); }
    @media (max-width: 1024px) {
      .product-product #product > .form-group {
        width: 80%; } }
    .product-product #product > .form-group:not(.time_collect):not(.time_return) .control-label {
      display: none; }
    .product-product #product > .form-group.time_collect {
      margin-right: 53px; }
    .product-product #product > .form-group .date input {
      flex: 1 1 auto;
      border-right: 0 !important;
      text-align: center; }
      .product-product #product > .form-group .date input:focus {
        border-color: #d7d7d7 !important; }
    .product-product #product > .form-group .date .input-group-btn {
      flex: 0 1 auto; }
      .product-product #product > .form-group .date .input-group-btn > * {
        min-width: auto !important;
        min-height: auto !important;
        height: auto !important;
        padding: 6px 12px !important;
        border: 1px solid #d7d7d7 !important;
        border-left: 0 !important; }
        .product-product #product > .form-group .date .input-group-btn > *::before {
          content: none !important; }
        .product-product #product > .form-group .date .input-group-btn > *:hover {
          color: #bf2031 !important; }

.checkbox {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  user-select: none; }
  .checkbox > * {
    flex: 0 1 auto;
    display: flex !important;
    align-items: center !important; }
    .checkbox > * > * {
      flex: 0 1 auto; }
  .checkbox .checkbox_name,
  .checkbox label {
    order: 2;
    padding: 0 !important;
    min-height: auto !important; }
  .checkbox .checkmark {
    position: relative;
    order: 1;
    display: block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    background-color: transparent;
    border: 1px solid #bf2031;
    transition: all 0.2s ease-in-out; }
    .checkbox .checkmark:hover {
      background-color: #bf2031; }
  .checkbox input {
    display: none !important; }
    .checkbox input:checked ~ .checkmark {
      background-color: #bf2031; }
      .checkbox input:checked ~ .checkmark::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); }

#side_filter .checkbox:not(:last-child),
#side-manufacturer .checkbox:not(:last-child) {
  margin-bottom: 5px !important; }

#side_filter label,
#side-manufacturer label {
  font-size: 14px; }

.radiobox:not(.td) {
  margin-top: 5px;
  margin-bottom: 0 !important; }

.radiobox.td {
  padding: 0;
  display: flex;
  align-items: center; }
  .radiobox.td > * {
    flex: 0 1 auto; }
  .radiobox.td .radiomark {
    margin-right: 5px; }

.radiobox .radiomark {
  height: 20px;
  width: 20px;
  background-color: #ddd;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .radiobox .radiomark:hover {
    background-color: #bf2031; }

.radiobox input {
  display: none !important; }
  .radiobox input:checked ~ .radiomark {
    background-color: #bf2031; }
    .radiobox input:checked ~ .radiomark:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: white; }

@media (min-width: 769px) {
  .quickcheckout-checkout .terms {
    display: flex;
    justify-content: flex-end;
    align-items: center; } }

@media (max-width: 768px) {
  .quickcheckout-checkout .terms .checkbox {
    margin-bottom: 10px !important; } }

@media (min-width: 769px) {
  .quickcheckout-checkout .terms .checkbox {
    margin-right: 20px !important; } }

@media (max-width: 576px) {
  .quickcheckout-checkout .terms .checkbox label {
    align-items: flex-start !important; } }

@media (max-width: 576px) {
  .quickcheckout-checkout .terms .checkbox .checkmark {
    margin-top: 3px; } }

.quickcheckout-checkout .terms .checkbox .checkbox_name {
  text-align: left; }

.quickcheckout-checkout #create_account .checkbox {
  margin-bottom: 10px !important; }

.quickcheckout-checkout .quickcheckout-checkbox .checkbox {
  margin-bottom: 10px !important; }

.page-banner .page-banner-title {
  text-transform: uppercase;
  text-align: center;
  font-size: 26px;
  font-weight: 600 !important; }
  @media (min-width: 769px) {
    .page-banner .page-banner-title {
      font-size: 30px; } }
  @media (min-width: 1025px) {
    .page-banner .page-banner-title {
      font-size: 36px; } }

.modal_container {
  background-color: #fff;
  padding: 0;
  position: relative; }
  .modal_container .mfp-close {
    color: #fff;
    transition: all 0.2s ease-in-out; }
    .modal_container .mfp-close:hover {
      transform: rotate(-90deg); }
  .modal_container .modal_banner img {
    width: 100%; }
  .modal_container .modal_content {
    padding: 20px;
    text-align: center; }
    .modal_container .modal_content p {
      margin-bottom: 0; }
    .modal_container .modal_content div {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 992px) {
        .modal_container .modal_content div {
          flex-direction: column; } }
      .modal_container .modal_content div > *:not(:last-child) {
        margin-right: 5px; }
        @media (max-width: 992px) {
          .modal_container .modal_content div > *:not(:last-child) {
            margin-right: 0; } }
      .modal_container .modal_content div a {
        display: flex;
        align-items: center; }
        .modal_container .modal_content div a:hover {
          color: #a41726; }
    .modal_container .modal_content img {
      width: 24px;
      transition: all 0.2s ease-in-out; }
      .modal_container .modal_content img:hover {
        transform: rotate(-360deg); }

.product-product #enquiry_modal {
  padding: 20px; }

#enquiry_modal .mfp-close {
  color: #000 !important; }

#loading_wrapper {
  overflow: hidden;
  height: 100vh;
  visibility: visible;
  opacity: 1; }

.side_nav {
  position: fixed;
  right: 5px;
  top: 30%;
  z-index: 5; }
  @media (min-width: 577px) {
    .side_nav {
      top: 20%; } }
  .side_nav .side_nav_content {
    display: flex;
    flex-direction: column; }
    .side_nav .side_nav_content > *:first-child {
      margin-bottom: 10px; }
    @media (max-width: 576px) {
      .side_nav .side_nav_content > * img {
        width: 50px; } }

@media (min-width: 1025px) {
  #about_us .img {
    width: 55%; } }

@media (min-width: 1025px) {
  #about_us .content {
    width: 45%;
    padding-left: 40px; } }

#about_us .content p {
  margin-bottom: 20px; }

@media (min-width: 1025px) {
  #services #sec_1 .r1 .img {
    width: 50%;
    order: 2; } }

@media (min-width: 1025px) {
  #services #sec_1 .r1 .content {
    width: 50%;
    padding-right: 40px; } }

#services #sec_1 .r1 .content p {
  margin-bottom: 20px; }

#services #sec_2 {
  background-color: #f5f5f5; }
  @media (min-width: 1025px) {
    #services #sec_2 .r1 .img {
      width: 50%; } }
  @media (min-width: 1025px) {
    #services #sec_2 .r1 .content {
      z-index: 2;
      margin-left: -20%;
      width: 70%;
      padding: 20px;
      background-color: #fff; } }
  @media (min-width: 1025px) {
    #services #sec_2 .r2 > * {
      width: calc(100% / 3); } }
  #services #sec_2 .r2 > *.content {
    padding: 20px;
    order: 1; }
  #services #sec_2 .r2 > *.img {
    order: 2; }
  @media (min-width: 1025px) {
    #services #sec_2 .rptr .img {
      width: 50%; } }
  @media (min-width: 1025px) {
    #services #sec_2 .rptr .content {
      z-index: 2;
      margin-left: -20%;
      width: 70%;
      padding: 20px;
      background-color: #fff; } }

.information-contact .google_map > * {
  width: 100% !important; }

@media (min-width: 992px) {
  .information-contact .contact_us_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; } }

.information-contact .contact_us_container > * {
  margin-bottom: 40px; }
  @media (min-width: 992px) {
    .information-contact .contact_us_container > * {
      flex: 0 1 auto; } }
  @media (min-width: 992px) {
    .information-contact .contact_us_container > *.contact_info_map {
      width: 50%; } }
  @media (min-width: 992px) {
    .information-contact .contact_us_container > *.contact_info {
      width: 50%;
      padding-left: 40px; } }
  .information-contact .contact_us_container > *.contact_info > *:not(.get_direction) a {
    color: #000; }
    .information-contact .contact_us_container > *.contact_info > *:not(.get_direction) a:hover {
      color: #bf2031; }
  @media (min-width: 992px) {
    .information-contact .contact_us_container > *.contact_form {
      width: 100%; } }

.information-contact .contact_us_container .get_direction {
  margin-top: 30px; }
  .information-contact .contact_us_container .get_direction h4 {
    text-transform: capitalize;
    margin-bottom: 0; }

#home_page #sec_1 {
  padding-bottom: 0; }
  #home_page #sec_1 .img_video_container {
    z-index: 1; }
    @media (min-width: 1025px) {
      #home_page #sec_1 .img_video_container {
        width: 50%;
        order: 1; } }
    #home_page #sec_1 .img_video_container p {
      margin-bottom: 0 !important; }
  #home_page #sec_1 .content {
    z-index: 0;
    padding: 20px;
    position: relative; }
    @media (min-width: 1025px) {
      #home_page #sec_1 .content {
        order: 2;
        width: 50%;
        padding: 20px 50px; } }
    #home_page #sec_1 .content::before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 70px;
      background-color: #ebebeb; }
    @media (min-width: 1281px) {
      #home_page #sec_1 .content .sec_title {
        width: 80%; } }
    @media (min-width: 1441px) {
      #home_page #sec_1 .content .sec_title {
        width: 60%; } }
    @media (min-width: 1025px) {
      #home_page #sec_1 .content > p {
        margin-top: -45px;
        padding-left: 88px; } }
    @media (min-width: 1281px) {
      #home_page #sec_1 .content > p {
        width: 80%; } }

#home_page #sec_2 {
  padding-top: 0 !important; }
  #home_page #sec_2 .img {
    align-self: flex-start; }
    @media (min-width: 1025px) {
      #home_page #sec_2 .img {
        width: 55%;
        order: 2; } }
  @media (min-width: 1025px) {
    #home_page #sec_2 .content {
      width: 45%;
      padding-top: 100px;
      padding-right: 40px; } }
  #home_page #sec_2 .car_types {
    display: flex;
    flex-wrap: wrap; }
    #home_page #sec_2 .car_types .item {
      width: 100%;
      padding: 30px 20px;
      flex: 0 1 auto;
      margin: 10px;
      text-align: center;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important; }
      @media (min-width: 577px) {
        #home_page #sec_2 .car_types .item {
          width: calc(50% - 20px); } }
      @media (min-width: 1281px) {
        #home_page #sec_2 .car_types .item {
          width: calc((100% / 3) - 20px); } }
    #home_page #sec_2 .car_types .title {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600 !important;
      text-transform: uppercase;
      margin-bottom: 0; }
      #home_page #sec_2 .car_types .title a {
        color: #000; }
        #home_page #sec_2 .car_types .title a:hover {
          color: #bf2031; }

#home_page #sec_3 {
  padding-top: 0;
  background: linear-gradient(0deg, #f5f5f5 0%, #f5f5f5 50%, #fff 50%, #fff 100%); }
  @media (min-width: 1025px) {
    #home_page #sec_3 .img {
      width: 45%; } }
  @media (min-width: 1025px) {
    #home_page #sec_3 .content {
      width: 55%;
      padding-left: 40px; } }
  #home_page #sec_3 .ls_periods {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1441px) {
      #home_page #sec_3 .ls_periods {
        width: 80%; } }
    #home_page #sec_3 .ls_periods .item {
      background-color: #fff;
      width: 100%;
      padding: 30px 20px;
      flex: 0 1 auto;
      margin: 10px;
      text-align: center;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important; }
      @media (min-width: 577px) {
        #home_page #sec_3 .ls_periods .item {
          width: calc(50% - 20px); } }
    #home_page #sec_3 .ls_periods .title {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600 !important;
      text-transform: uppercase;
      margin-bottom: 0; }
      #home_page #sec_3 .ls_periods .title a {
        color: #000; }
        #home_page #sec_3 .ls_periods .title a:hover {
          color: #bf2031; }

.common-home .featured-module {
  padding-top: 0;
  background-color: #f5f5f5; }
  .common-home .featured-module > .featured {
    padding: 0; }
    .common-home .featured-module > .featured > *:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      .common-home .featured-module > .featured > *:first-child .slick-list {
        width: 100%;
        order: 1;
        margin-bottom: 50px; }
      .common-home .featured-module > .featured > *:first-child .slick-nav {
        order: 2;
        left: auto;
        right: auto;
        width: auto; }
        .common-home .featured-module > .featured > *:first-child .slick-nav.prev {
          margin-right: 5%; }
          @media (min-width: 577px) {
            .common-home .featured-module > .featured > *:first-child .slick-nav.prev {
              margin-right: 25%; } }
        .common-home .featured-module > .featured > *:first-child .slick-nav.next {
          margin-left: 5%; }
          @media (min-width: 577px) {
            .common-home .featured-module > .featured > *:first-child .slick-nav.next {
              margin-left: 25%; } }
      .common-home .featured-module > .featured > *:first-child .icon_con {
        color: #fff;
        background-color: #a9a9a9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px; }
        .common-home .featured-module > .featured > *:first-child .icon_con i {
          font-size: 18px; }
  .common-home .featured-module .btn_con {
    text-align: center;
    margin-top: 20px; }
    @media (min-width: 577px) {
      .common-home .featured-module .btn_con {
        margin-top: -42px; } }
  .common-home .featured-module .product-block {
    background-color: #f5f5f5;
    padding-bottom: 0; }
    .common-home .featured-module .product-block .product-details {
      display: none; }
