#about_us {
  .img {
    @include to(lap) {
      width: 55%;
    }
  }

  .content {
    @include to(lap) {
      width: 45%;
      padding-left: 40px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}