.slider-slideshow-description-texts {

  >[class^=slideshow-text-] {
    color: $w !important;
    font-size: 20px !important;
    text-transform: uppercase;
    text-shadow: 0px 0px 10px #000;
    @include fb;

    @include to(lap) {
      font-size: 34px !important;
    }
  }

  .slider-slideshow-description-link {

    .btn {
      color: $w !important;
      border-color: $w !important;

      &:hover {
        border-color: $p !important;
      }
    }
  }
}

.owl-nav {
  position: absolute;
  bottom: 10%;
  right: 10%;
  display: flex;
  z-index: 2;

  @include to(lapx) {
    display: none;
  }

  >* {
    width: auto !important;
    position: initial;
  }

  .arrows {
    width: 50px;
    height: 50px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $w;
    background-color: rgba($b, 0.5);
    margin: 3px;

    i {
      font-size: 24px;
    }
  }

  .owl-prev {
    order: 1;
  }

  .owl-next {
    order: 2;
  }
}

.slider-dots {
  padding: 0px 47px;
  bottom: 10%;
  right: 0;

  .owl-dot {
    width: 35px;
    border-radius: 0 !important;
    height: 5px !important;
    background-color: $w !important;
    @include t;

    @include to(sm) {
      width: 60px !important;
    }

    &.active {
      background-color: $s !important;
    }
  }
}