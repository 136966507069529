.checkbox {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  user-select: none;

  >* {
    flex: 0 1 auto;
    display: flex !important;
    align-items: center !important;

    >* {
      flex: 0 1 auto;
    }
  }

  .checkbox_name,
  label {
    order: 2;
    padding: 0 !important;
    min-height: auto !important;
  }

  .checkmark {
    position: relative;
    order: 1;
    display: block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    background-color: transparent;
    border: 1px solid $p;
    @include t;

    &:hover {
      background-color: $p;
    }
  }

  input {
    display: none !important;

    &:checked~.checkmark {
      background-color: $p;

      &::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

// Product Listing filter / Brands
#side_filter,
#side-manufacturer {
  .checkbox {
    &:not(:last-child) {
      margin-bottom: 5px !important;
    }
  }

  label {
    font-size: 14px;
  }
}