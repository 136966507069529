.product-product {

  // Product Name
  .product-name {
    color: $b !important;
  }

  .leasing_period {
    text-transform: uppercase;
    @include fb;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .product-description {
    margin-bottom: 20px;
  }

  // Product Price 
  ul {
    &.price-details {
      display: flex;
      margin: 20px 0;

      >* {
        color: $p;
        @include fb;
        flex: 0 1 auto;
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;
      }

      &:not(.price-special) {
        &:first-child {
          order: 2;
        }

        &:last-child {
          order: 1;
          margin-right: 10px;
        }
      }

      &.price-special {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }



  .product-image-column {

    .product-image-main-container {
      overflow: visible;
    }

    .product-image-additional-container {
      padding: 0;

      img {
        padding: 0;
        border: 0;
      }
    }

    .product-image-additional {
      .slick-current {
        img {
          opacity: 0.7;
        }
      }
    }

    .slick-track {
      margin-left: 0;
      margin-right: 0;
    }
  }

  #product {

    .product-btns-container {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;

      >* {
        margin-bottom: 5px;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    .separator_to {
      margin: 0 20px 24px;
      text-align: center;

      @include to(lapx) {
        width: 80%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }


  // Share Icons
  .share-container {
    display: none;
    margin: 20px 0;


    a {
      background-color: transparent !important;

      svg {
        fill: $txt !important;
      }
    }
  }

  // Enquiry
  .enquiry-block {
    margin: 20px 0;

    .label {
      text-transform: uppercase;
      color: $txt;
      background-color: transparent !important;
      font-size: 18px;
      padding: 0;
    }
  }

  // Out of Stock
  .product_oos {
    @include fb;
    font-size: 20px;
    color: #a8a8a8 !important;
    margin-bottom: 20px;
  }

  #waiting_list {
    display: none;

    .flex-group {

      @include to(tabx) {
        justify-content: flex-start;
        flex-direction: column;
      }

      >* {

        &:first-child {
          flex: 1 0 auto;

          @include to(tabx) {
            margin-bottom: 10px;
            margin-right: 0;
          }
        }

        &:last-child {
          flex: 0 1 auto;

          @include to(tabx) {
            align-self: flex-end;
          }
        }
      }
    }

    input {
      padding: 13px 10px;

    }
  }

  // Product Attributes
  #product-tabs {
    margin-top: 30px;
    border-color: $b;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }

      a {
        color: $txt;
        border: 1px solid $b;
        border-radius: $rds;
        margin: 0;
        text-transform: uppercase;

        @include to(mob) {
          text-align: center;
        }
      }

      &.active {

        a {
          background-color: $p !important;
          color: $w;
          border-color: $p !important;
        }
      }
    }
  }

  .tab-content {

    table {
      td {
        padding: 5px;
      }
    }
  }

  .featured-module {
    margin-top: 70px;

    .product-details {
      display: none;
    }

    .slick-list {
      width: 90%;
      margin: 0 auto;
    }

    .fa {
      background-color: #b0b0b0;
      color: $w;
      font-size: 18px;

      &::before {
        display: block;
        padding: 15px 30px;

        @include to(smx) {
          font-size: 16px;
          padding: 5px 10px;
        }
      }
    }
  }

  #product {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include to(lapx) {
      flex-direction: column;
    }

    h3 {
      text-transform: uppercase;
      width: 100%;
    }

    >.form-group {
      width: calc(50% - 53px);

      @include to(lapx) {
        width: 80%;
      }

      &:not(.time_collect):not(.time_return) {


        .control-label {
          display: none;
        }
      }

      &.time {
        &_collect {
          margin-right: 53px;
        }
      }

      .date {

        input {
          flex: 1 1 auto;
          border-right: 0 !important;
          text-align: center;

          &:focus {
            border-color: $bdr !important;
          }
        }

        .input-group-btn {
          flex: 0 1 auto;

          >* {
            min-width: auto !important;
            min-height: auto !important;
            height: auto !important;
            padding: 6px 12px !important;
            border: 1px solid $bdr !important;
            border-left: 0 !important;

            &::before {
              content: none !important;
            }

            &:hover {
              color: $p !important;
            }
          }
        }
      }
    }
  }
}