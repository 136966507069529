input,
textarea {
  box-shadow: none !important;
  outline: none;
  @include t;


  &:not([name=search]) {
    border: 1px solid $bdr !important;
  }

  &:focus {
    outline: none;
    box-shadow: none !important;
    border-color: $b !important;
  }

  &::-ms-clear {
    display: none !important;
  }
}

select {
  padding: 10px !important;
  height: auto !important;
  border: 1px solid $bdr !important;
  box-shadow: none !important;
  appearance: none !important;

  background-image: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%) !important;
  background-position: calc(100% - 15px) calc(1em + 6px), calc(100% - 10px) calc(1em + 6px) !important;
  background-size: 5px 5px, 5px 5px !important;
  background-repeat: no-repeat !important;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
    box-shadow: none !important;
    border-color: $b !important;
  }
}

//recaptcha
#google_recaptcha {
  transform: inherit;
  transform-origin: none;
  margin-bottom: 15px !important;
  margin-left: 0 !important;

  @include to(mob) {
    margin-bottom: 0 !important;
  }
}

.g-recaptcha {

  transform: scale(0.7);
  transform-origin: 0;

  @include to(mobx) {
    transform-origin: center;
  }
}

.form-item {

  .title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .text-danger {
    padding-left: 1px;
    margin-top: 5px;
    font-size: 11px;
  }

  .rating {
    display: flex;
    align-items: center;

    .radiobox {
      margin-top: 0;
    }

    .radiomark {
      margin: 5px !important;
    }

    span {

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  &.items:not(.form-footer) {
    @include to(mob) {
      display: flex;
      justify-content: space-between;
    }

    >.item {
      @include to(mob) {
        flex: 0 1 auto;
        width: calc(50% - 10px);
      }

      margin-bottom: 20px;
    }
  }

  &:not(.items) {
    >.item {
      margin-bottom: 10px;
    }
  }

  &.form-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include to(mobx) {
      flex-direction: column;
    }
  }

  select {
    width: 100%;
    line-height: 1em;
    background-position: calc(100% - 15px) 1em, calc(100% - 10px) 1em !important;
    text-transform: uppercase;
  }
}

.alert {
  border: 0 !important;
  border-radius: $rds;
}

.rating,
#form-review {

  .fa-star {
    color: $star !important;

    &+.fa-star-o {
      color: $star !important;
    }
  }
}

::placeholder {
  text-transform: uppercase;
}