// Terms
.quickcheckout-checkout {

  .terms {
    @include to(mob) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .checkbox {

      @include to(mobx) {
        margin-bottom: 10px !important;
      }

      @include to(mob) {
        margin-right: 20px !important;
      }

      label {
        @include to(smx) {
          align-items: flex-start !important;
        }
      }

      .checkmark {
        @include to(smx) {
          margin-top: 3px;
        }
      }

      .checkbox_name {
        text-align: left;
      }
    }
  }

  #create_account {

    .checkbox {
      margin-bottom: 10px !important;
    }
  }

  .quickcheckout-checkbox {
    .checkbox {
      margin-bottom: 10px !important;
    }
  }
}