.radiobox {

  &:not(.td) {
    margin-top: 5px;
    margin-bottom: 0 !important;
  }

  &.td {
    padding: 0;
    display: flex;
    align-items: center;

    >* {
      flex: 0 1 auto;
    }

    .radiomark {
      margin-right: 5px;
    }
  }

  .radiomark {
    height: 20px;
    width: 20px;
    background-color: #ddd;
    border-radius: 50%;
    @include t;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $p;
    }
  }

  .radiobox_name {}

  input {
    display: none !important;

    &:checked~.radiomark {
      background-color: $p;


      &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
      }
    }
  }
}