#services {
  #sec_1 {
    .r1 {
      .img {

        @include to(lap) {
          width: 50%;
          order: 2;
        }
      }

      .content {
        @include to(lap) {
          width: 50%;
          padding-right: 40px;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }
  }

  #sec_2 {
    background-color: $bg;

    .r1 {

      .img {
        @include to(lap) {
          width: 50%;
        }
      }

      .content {
        @include to(lap) {
          z-index: 2;
          margin-left: -20%;
          width: 70%;
          padding: 20px;
          background-color: $w;
        }
      }
    }

    .r2 {

      >* {
        @include to(lap) {
          width: calc(100% / 3);
        }

        &.content {
          padding: 20px;
          order: 1;
        }

        &.img {
          order: 2;
        }
      }
    }

    .rptr {
      .img {
        @include to(lap) {
          width: 50%;
        }
      }

      .content {
        @include to(lap) {
          z-index: 2;
          margin-left: -20%;
          width: 70%;
          padding: 20px;
          background-color: $w;
        }
      }
    }
  }
}