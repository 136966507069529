.page-banner {

  .page-banner-title {
    text-transform: uppercase;
    text-align: center;
    font-size: 26px;
    @include fsb;

    @include to(mob) {
      font-size: 30px;
    }

    @include to(lap) {
      font-size: 36px;
    }
  }
}