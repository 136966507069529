.information-contact {

  .google_map {
    >* {
      width: 100% !important;
    }
  }

  .contact_us_container {

    @include to(tab) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    >* {
      margin-bottom: 40px;

      @include to(tab) {
        flex: 0 1 auto;
      }

      &.contact_info_map {
        @include to(tab) {
          width: 50%;
        }
      }

      &.contact_info {
        @include to(tab) {
          width: 50%;
          padding-left: 40px;
        }

        >* {
          &:not(.get_direction) {
            a {
              color: $b;

              &:hover {
                color: $p;
              }
            }
          }
        }

      }

      &.contact_form {
        @include to(tab) {
          width: 100%;
        }
      }

    }

    .get_direction {
      margin-top: 30px;

      h4 {
        text-transform: capitalize;
        margin-bottom: 0;
      }
    }
  }
}